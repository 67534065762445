/** @jsxImportSource theme-ui */
import React, { FC, memo, useEffect, useState } from 'react';

import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from 'theme-ui';

import heroMessages from './intl';

import {
  AttributeWarning,
  MovieRatingWarning,
  ScreenWarning,
} from '../../../@types/contentTypes';
import * as StepPaths from '../../../constants/stepPaths';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  getDisplayDateAndTime,
  renderShowtimeAttributeList,
  getScreenWarning,
  getAttributeWarnings,
  getMovieRatingWarning,
} from '../../../services/Helpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectCountdownEndTime,
  selectCustomer,
  selectIsSeatsFirstJourney,
  selectSelectedLanguageCulture,
  selectShowtimes,
} from '../../../store/Selectors';
import { ReactComponent as SummaryCloseSvg } from '../../../svgs/summaryClose.svg';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar';
import CartSummaryDiscount from '../../common/cartsummary/CartSummaryDiscount';
import CartSummaryItems from '../../common/cartsummary/CartSummaryItems';
import CartSummaryTotal from '../../common/cartsummary/CartSummaryTotal';
import ContainedRow from '../../common/layout/ContainedRow';
import MobileWallets from '../../common/mobilewallets/MobileWallets';
import ShowtimePicker from '../../common/showtimepicker/ShowtimePicker';
import ShowtimeWarning from '../../common/showtimewarning/ShowtimeWarning';
import TotalWrapper from '../../common/totalwrapper/TotalWrapper';
import globalMessages from '../intl';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyShowtimeHeroWidget'>;
  barcodeUrl?: string;
  displayAddToCalendar?: boolean;
  displayBarcode?: boolean;
  displayConfirmationNumber?: boolean;
  displayMobileWallets?: boolean;
  orderId?: string;
};

export const ShowtimeHero: FC<Props> = ({
  widget,
  barcodeUrl,
  displayAddToCalendar,
  displayBarcode,
  displayConfirmationNumber,
  displayMobileWallets,
  orderId,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { screenWidth } = useScreenWidth();
  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const showtimes = useSelector(selectShowtimes);
  const { isLargeScreenWidth } = useScreenWidth();
  const [showtimePickerOpen, setShowtimePickerOpen] = useState(false);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);
  const countdownEndTime = useSelector(selectCountdownEndTime);
  const movieRatingWarnings: MovieRatingWarning[] = getMovieRatingWarning(
    cartSummary.rated,
    content.movieRatingWarnings
  );
  const displaySummary =
    (pathname.startsWith('/payment') && screenWidth < 992) ||
    (pathname.startsWith('/confirmation') && screenWidth < 992);

  const allowShowtimeChange = widget.shape?.allowShowtimeChange || false;

  const displayShowtimePicker =
    allowShowtimeChange &&
    (pathname.startsWith('/seats/') || pathname.startsWith('/tickets/'));

  const attributeWarnings: AttributeWarning[] = getAttributeWarnings(
    cartSummary.sessionAttributes,
    content.attributeWarnings
  );

  const screenWarning: ScreenWarning | undefined = getScreenWarning(
    cartSummary.screen,
    content.screenWarnings,
    config.currentCinema.cinemaId
  );

  const displayWarningMessage =
    pathname.startsWith(`/${StepPaths.WARNING}`) &&
    (!!attributeWarnings.length || screenWarning);

  const showShowtimeHeroExtendedArea =
    displayShowtimePicker || displayWarningMessage;

  const dot = <span className='dot-separator'>·</span>;

  useEffect(() => {
    const fetchSessions = async () => {
      const url = `/api/ShowtimePicker/Showtimes/
        ${bookingData.circuitId}/
        ${bookingData.cinemaId}/
        ${bookingData.filmId}`;

      const response = await backend.get(url);

      if (response.ok) {
        response.content &&
          dispatch(actionCreators.setShowtimes(response.content));
      }
    };
    if (
      showShowtimeHeroExtendedArea &&
      bookingData &&
      !showtimes &&
      !pathname.startsWith('/confirmation') &&
      !pathname.startsWith('/refund')
    ) {
      fetchSessions();
    }
  }, [
    bookingData,
    dispatch,
    showShowtimeHeroExtendedArea,
    pathname,
    showtimes,
  ]);

  const handleHideSessions = () => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
    setShowtimePickerOpen(false);
  };

  const setShowShowtimes = (show: boolean) => {
    if (show) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
      setShowtimePickerOpen(show);
    } else {
      handleHideSessions();
    }
  };

  if (
    !content ||
    !content.cartSummary ||
    !customer ||
    !cartSummary ||
    !cartSummary.sessionDate
  )
    return null;

  const backgroundImage = cartSummary.stillUrl
    ? cartSummary.stillUrl
    : cartSummary.posterUrl;

  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <>
      <ContainedRow>
        {showShowtimeHeroExtendedArea && (
          <div
            className={classnames(showtimePickerOpen && 'showtime-picker-mask')}
            sx={{
              backgroundColor: 'bodyMask',
            }}
          />
        )}
        <div
          className={classnames(
            'showtime-hero',
            showShowtimeHeroExtendedArea &&
              showtimePickerOpen &&
              'hide-opacity showtime-picker-view'
          )}
          data-testid='showtime-hero'
          sx={{
            textAlign: 'center',
            mt: 4,
            mb: showShowtimeHeroExtendedArea && showtimePickerOpen ? 4 : 0,
            boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
          }}
        >
          <div className='showtime-hero-top'>
            <div
              className='backdrop blur'
              style={{ backgroundImage: `url(${backgroundImage}?width=770)` }}
            />
            <div className='caption'>
              {showShowtimeHeroExtendedArea && showtimePickerOpen && (
                <Button
                  className='close-showtime-picker-button'
                  onClick={() => handleHideSessions()}
                  variant='link'
                >
                  <SummaryCloseSvg className='icon' />
                </Button>
              )}
              <h2>{cartSummary.title}</h2>
              {!(showShowtimeHeroExtendedArea && showtimePickerOpen) && (
                <p className='tiny'>
                  {cartSummary.rated && <span>{cartSummary.rated}</span>}
                  {cartSummary.formattedDuration && cartSummary.rated && (
                    <span>{dot}</span>
                  )}
                  {cartSummary.formattedDuration && (
                    <span>{cartSummary.formattedDuration}</span>
                  )}
                </p>
              )}
              {!(showShowtimeHeroExtendedArea && showtimePickerOpen) && (
                <p className='small extra-margin'>
                  {renderShowtimeAttributeList(
                    cartSummary.sessionAttributes,
                    widget.shape?.attributeVariantShowtimeHero,
                    true
                  )}
                </p>
              )}
              <p className='extra-margin'>
                <b>{formatMessage(globalMessages.atLabel)} </b>
                {cartSummary.theaterLocationName}
                {dot}
                {cartSummary.screen}
              </p>
              {(!displayShowtimePicker ||
                (displayShowtimePicker && showtimePickerOpen)) && (
                <p>
                  <b>{formatMessage(globalMessages.onLabel)} </b>
                  {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
                </p>
              )}
            </div>
          </div>
          {displayBarcode && (
            <div sx={{ mt: 5, px: 4 }}>
              <img src={barcodeUrl} alt='ticket barcode' />
            </div>
          )}
          {displayMobileWallets && orderId && (
            <MobileWallets orderId={orderId} />
          )}
          {displayAddToCalendar && displayConfirmationNumber !== undefined && (
            <AddToCalendar
              displayConfirmationNumber={displayConfirmationNumber}
              locationCinemaName={config.currentCinema.title}
            />
          )}
          {displaySummary && (
            <div
              className='cart-summary'
              sx={{
                px: 4,
                backgroundColor: 'uiContainerBackground',
                '.cart-items:first-child': {
                  borderTop: 'none',
                },
                '.cart-summary-row': {
                  borderTop: 'bodySeparator',
                },
                '.cart-summary-total': {
                  backgroundColor: 'uiContainerBackground',
                },
              }}
            >
              <CartSummaryItems />
              <CartSummaryDiscount />
              <CartSummaryTotal />
            </div>
          )}
          {(displayShowtimePicker || displayWarningMessage) && (
            <div
              className='showtime-hero-session-picker'
              sx={{
                backgroundColor: 'uiContainerBackground',
              }}
            >
              {showtimePickerOpen && (
                <h2 sx={{ py: 3 }}>
                  {formatMessage(heroMessages.selectShowtimeText)}
                </h2>
              )}
              {!showtimePickerOpen && displayShowtimePicker && (
                <Box sx={{ px: 4 }}>
                  <div
                    sx={{
                      borderBottom: 'bodySeparator',
                      py: 2,
                    }}
                  >
                    <p sx={{ m: 0 }}>
                      <b>{formatMessage(globalMessages.onLabel)} </b>
                      {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
                    </p>
                  </div>
                </Box>
              )}
              <ShowtimePicker
                showShowtimes={showtimePickerOpen}
                setShowShowtimes={setShowShowtimes}
                displayShowtimePicker={displayShowtimePicker}
              />
            </div>
          )}
          {displayWarningMessage && (
            <ShowtimeWarning
              setShowShowtimes={setShowShowtimes}
              attributeWarnings={attributeWarnings}
              screenWarning={screenWarning}
              movieRatingWarnings={movieRatingWarnings}
            />
          )}
        </div>
      </ContainedRow>
      {isLargeScreenWidth &&
        (!isSeatsFirstJourney || countdownEndTime !== null) &&
        !pathname.startsWith(`/${StepPaths.LOGIN}`) && <TotalWrapper />}
    </>
  );
};

export default memo(ShowtimeHero);
