import React, { useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeUIProvider } from 'theme-ui';
import type { Theme } from 'theme-ui';

import { AnalyticsProvider } from './analytics/analyticsContext';
import AppRoutes from './AppRoutes';
import LoadingMask from './components/common/loadingmask/LoadingMask';
import { ScreenWidthProvider } from './contextProviders/screenWidthContext';
import { TurnstileProvider } from './contextProviders/turnstileContext';
import { useDidomi } from './hooks/useDidomi';
import { createTheme } from './services/CreateTheme';
import {
  selectConfig,
  selectDazzlerContent,
  selectLoading,
} from './store/Selectors';

import './scss/app.scss';

export interface ProviderProps {
  children: React.ReactNode;
}

const App = () => {
  useDidomi();

  const config = useSelector(selectConfig);
  const loading = useSelector(selectLoading);
  const dazzlerContent = useSelector(selectDazzlerContent);

  const [theme, setTheme] = useState<Theme>({});

  useEffect(() => {
    if (config) {
      const newTheme = createTheme(config);
      setTheme(newTheme);
    }
  }, [config]);

  return (
    <div className='app'>
      {loading && <LoadingMask />}
      <IntlProvider messages={dazzlerContent} locale='en' defaultLocale='en'>
        <ThemeUIProvider theme={theme}>
          <BrowserRouter>
            <AnalyticsProvider>
              <TurnstileProvider>
                <ScreenWidthProvider>
                  <AppRoutes />
                </ScreenWidthProvider>
              </TurnstileProvider>
            </AnalyticsProvider>
          </BrowserRouter>
        </ThemeUIProvider>
      </IntlProvider>
    </div>
  );
};

export default App;
