/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { GlobalState } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectGrandTotalAfterDiscountsInCents,
  selectDisplayPrice,
  selectLoyaltyRedemptionRewards,
} from '../../../../store/Selectors';
import RichText from '../../richtext/RichText';

interface Props {
  loyaltyPaymentCoversFullAmount: boolean;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
}

const SplitLoyaltyRedemptionBreakDown: React.FC<Props> = ({
  loyaltyPaymentCoversFullAmount,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const loyaltyRedemptionRewards = useSelector(selectLoyaltyRedemptionRewards);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );

  useEffect(() => {
    if (loyaltyRedemptionRewards > grandTotalWithDiscount) {
      dispatch(
        actionCreators.setLoyaltyRedemptionRewards(grandTotalWithDiscount)
      );
    }
  }, [loyaltyRedemptionRewards, grandTotalWithDiscount, dispatch]);

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, loyaltyRedemptionRewards)
  );

  const handleRemoveLoyaltyRedemption = () => {
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
    setPaymentFormVisibility(true);
    setGiftCardFormVisibility && setGiftCardFormVisibility(true);
  };

  return (
    <Box
      className='breakdown'
      data-testid='loyalty-redemption-break-down'
      mt={2}
    >
      <p>{content.payment.loyaltyRedemptionDollarsAppliedText}</p>
      <RichText
        text={content.payment.loyaltyRedemptionRemainingBalanceRichText}
      />
      {!loyaltyPaymentCoversFullAmount && (
        <RichText text={content.payment.loyaltyRedemptionStillToPayRichText} />
      )}
      <Flex
        className='border-y-solid'
        sx={{ pr: 4, mb: 2, mt: 4, justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            flexGrow: 1,
            mr: 3,
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          {content.payment.loyaltyRedemptionDollarsAppliedLabel}
        </Box>
        <Flex sx={{ mr: 3 }}>
          <Button
            onClick={handleRemoveLoyaltyRedemption}
            variant='link'
            size='sm'
            className='small'
            sx={{ textTransform: 'uppercase' }}
          >
            {content.payment.removeButtonText}
          </Button>
        </Flex>
        <Flex>
          <span sx={{ float: 'right', fontWeight: 'bold' }}>
            {priceToDisplay}
          </span>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SplitLoyaltyRedemptionBreakDown;
