/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  concessionItemSxProp,
  descriptionSxProp,
  detailSxProp,
  priceSxProp,
} from './constants';

import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectLoyaltyRedemptionRewards,
} from '../../../store/Selectors';

const CartSummaryLoyaltyRedemption: React.FC = () => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const loyaltyRedemptionRewards = useSelector(selectLoyaltyRedemptionRewards);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const priceToDisplay = displayPrice(
    loyaltyRedemptionRewards < grandTotalWithDiscount
      ? loyaltyRedemptionRewards
      : grandTotalWithDiscount,
    currencyConfig
  );

  if (!loyaltyRedemptionRewards) return null;

  const handleRemoveLoyaltyRedemption = () => {
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
  };

  return (
    <Flex
      className='cart-summary-row'
      data-testid='cs-loyalty-redemption'
      sx={{ ...concessionItemSxProp, width: '100%' }}
    >
      <Flex sx={descriptionSxProp}>
        {content.payment.loyaltyRedemptionDollarsAppliedLabel}
      </Flex>
      <Flex sx={detailSxProp}>
        <Flex sx={{ alignItems: 'center' }}>
          <Button
            onClick={handleRemoveLoyaltyRedemption}
            variant='link'
            size='sm'
            className='small'
            sx={{ textTransform: 'uppercase' }}
          >
            {content.payment.removeButtonText}
          </Button>
        </Flex>
        <Box className='price' sx={priceSxProp}>
          {priceToDisplay}
        </Box>
      </Flex>
    </Flex>
  );
};

export default CartSummaryLoyaltyRedemption;
