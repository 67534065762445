import React from 'react';

import { useSelector } from 'react-redux';

import AdyenDropinPayment from './providers/adyen/AdyenDropinPayment';
import AmazonPay from './providers/AmazonPay';
import HeartlandRtsPayment from './providers/HeartlandRtsPayment';
import IntegratedPayment from './providers/IntegratedPayment';
import PayrixPayment from './providers/payrix/PayrixPayment';
import RtsVantivPayment from './providers/RtsVantivHostedPayment';
import SpreedlyPayment from './providers/SpreedlyPayment';
import StripeCheckoutPayment from './providers/StripeCheckoutPayment';
import StripeCheckoutPaymentV2 from './providers/StripeCheckoutPaymentV2';
import VantivHostedPayment from './providers/vantivHP/VantivHostedPaymentForm';
import VantivIframePayment from './providers/vantivHP/VantivIframePayment';
import VantivIframeStoredCardPayment from './providers/vantivHP/VantivIframeStoredCardPayment';
import ZeroBankPayment from './providers/ZeroBankPayment';

import { PaymentProvidersEnum } from '../../../@types/enums';
import { PaymentConfig } from '../../../@types/modelTypes';
import { useInitializePaymentProvider } from '../../../hooks/useInitialisePaymentProvider';
import {
  selectGrandTotalAfterDiscountsInCents,
  selectPayWithStoredCardEnabled,
} from '../../../store/Selectors';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setCreditCardType: (cardType: string) => void;
  paymentProvider: PaymentProvidersEnum;
  paymentConfig: PaymentConfig;
}

const PaymentProviderSelector: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
  setCreditCardType,
  paymentProvider,
  paymentConfig,
}) => {
  const payWithStoredCardEnabled = useSelector(selectPayWithStoredCardEnabled);

  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const { initializeProviderResponses, requiresInitialization } =
    useInitializePaymentProvider(paymentProvider, grandTotal);

  const getPaymentProviderComponent = () => {
    if (grandTotal === 0) {
      return (
        <ZeroBankPayment
          isPageValidated={isPageValidated}
          handleValidatePage={handleValidatePage}
        />
      );
    }
    switch (paymentProvider) {
      case PaymentProvidersEnum.SPREEDLY:
        return (
          <SpreedlyPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            initializePaymentProviderResponse={
              initializeProviderResponses[PaymentProvidersEnum.SPREEDLY]
            }
            setCreditCardType={setCreditCardType}
          />
        );
      case PaymentProvidersEnum.PAYRIX:
        return (
          <PayrixPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            initializePaymentProviderResponse={
              initializeProviderResponses[PaymentProvidersEnum.PAYRIX]
            }
            paymentConfig={paymentConfig}
          />
        );
      case PaymentProvidersEnum.ADYEN:
        return <AdyenDropinPayment />;
      case PaymentProvidersEnum.STRIPEV2:
        return <StripeCheckoutPaymentV2 />;
      case PaymentProvidersEnum.STRIPEPOPUP:
        return (
          <StripeCheckoutPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            initializePaymentProviderResponse={
              initializeProviderResponses[PaymentProvidersEnum.STRIPEPOPUP]
            }
          />
        );
      case PaymentProvidersEnum.VANTIVHP:
        return <VantivHostedPayment />;
      case PaymentProvidersEnum.VANTIVHPIFRAME:
        return payWithStoredCardEnabled ? (
          <VantivIframeStoredCardPayment />
        ) : (
          <VantivIframePayment />
        );
      case PaymentProvidersEnum.RTSVANTIV:
        return <RtsVantivPayment paymentProvider={paymentProvider} />;
      case PaymentProvidersEnum.AMAZONPAY:
        return (
          <AmazonPay
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
          />
        );
      case PaymentProvidersEnum.WORLDPAYAPPLEPAY:
        return null;
      case PaymentProvidersEnum.RTSHEARTLAND:
        return (
          <HeartlandRtsPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            initializePaymentProviderResponse={
              initializeProviderResponses[PaymentProvidersEnum.RTSHEARTLAND]
            }
            setCreditCardType={setCreditCardType}
          />
        );
      default:
        return (
          <IntegratedPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            useAddress={paymentProvider === PaymentProvidersEnum.RTSCARDPAYMENT}
            useCardType={
              paymentProvider !== PaymentProvidersEnum.RTSCARDPAYMENT
            }
            setCreditCardType={setCreditCardType}
          />
        );
    }
  };

  return requiresInitialization ? null : getPaymentProviderComponent();
};

export default PaymentProviderSelector;
