/** @jsxImportSource theme-ui */
import React from 'react';

import { Tab, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentConfig } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectContent,
  selectConfig,
} from '../../../../store/Selectors';
import { ReactComponent as GiftCardSvg } from '../../../../svgs/giftcard.svg';
import PaymentIconsContainer from '../../../widgets/paymentform/paymenticons/PaymentIconsContainer';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import GiftCardPayment from '../giftcardcomponents/GiftCardPayment';
import CreditCardPaymentsContainer from '../paymentcomponents/cardpayments/CreditCardPaymentsContainer';

interface Props {
  creditCardType: string;
  showGiftCardForm: boolean;
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  setCreditCardType: (creditCardType: string) => void;
}

const TabbedFullPaymentDesign: React.FC<Props> = ({
  creditCardType,
  showGiftCardForm,
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setCreditCardType,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const appliedGiftCards = useSelector(selectAppliedGiftCards);

  const handleRemoveOtherPayments = () => {
    if (appliedGiftCards.length) {
      dispatch(actionCreators.removeAllGiftCards());
    }
    dispatch(actionCreators.setSelectedPaymentProvider(null));
    dispatch(actionCreators.removeHostedPayments());
  };
  const paymentConfig: PaymentConfig = {
    hideVisa: config.payment.hideVisa,
    hideMastercard: config.payment.hideMastercard,
    hideAmex: config.payment.hideAmex,
    hideDiscover: config.payment.hideDiscover,
    hideMaestro: config.payment.hideMaestro,
    showApplePay: config.payment.showApplePay,
    showGooglePay: config.payment.showGooglePay,
  };
  return (
    <div
      className='full-payment-wrapper'
      data-testid='tabbed-full-payment-design'
      sx={{
        textAlign: 'left',
        '.tab-content': {
          border: 'mostReadableOnWebsiteBackgroundBorder',
        },

        '.nav-tabs': {
          backgroundColor: 'boxHeaderBackground',

          '& .nav-link.active, & .nav-item.show .nav-link': {
            border: 'mostReadableOnWebsiteBackgroundBorder',
            borderBottom: 'none',
          },
        },

        '.nav-item': {
          'a.active': {
            backgroundColor: 'boxBackground',
            color: 'mostReadableOnWebsiteBackground',
          },

          'a:not(.active)': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderBottom: 'mostReadableOnWebsiteBackgroundBorder',
          },
        },
      }}
    >
      <ContainedRow styles={{ my: 5 }}>
        <div className='tabbed-container bordered'>
          <Tab.Container id='left-tabs-example' defaultActiveKey='payment'>
            <Nav fill variant='tabs' className='flex-row'>
              <Nav.Item>
                <Nav.Link
                  eventKey='payment'
                  onClick={handleRemoveOtherPayments}
                >
                  {content.payment.tabHeadingCreditCard}
                  <PaymentIconsContainer
                    paymentDesignName='tabbed'
                    creditCardType={creditCardType}
                    paymentConfig={paymentConfig}
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='giftcard'>
                  {content.payment.tabHeadingGiftCard}
                  <div className='payment-icons' sx={{ my: 4 }}>
                    <GiftCardSvg className='giftcard' />
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content sx={{ p: 5 }}>
              <Tab.Pane eventKey='payment'>
                <CreditCardPaymentsContainer
                  creditCardType={creditCardType}
                  isPageValidated={isPageValidated}
                  handleValidatePage={handleValidatePage}
                  setCreditCardType={setCreditCardType}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='giftcard'>
                {content.payment.giftCardEnterFullPaymentRichText && (
                  <RichText
                    styles={{ mb: 4 }}
                    text={content.payment.giftCardEnterFullPaymentRichText}
                  />
                )}
                <GiftCardPayment
                  setPaymentFormVisibility={setPaymentFormVisibility}
                  isPageValidated={isPageValidated}
                  handleValidatePage={handleValidatePage}
                  isShown={showGiftCardForm}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </ContainedRow>
    </div>
  );
};

export default TabbedFullPaymentDesign;
