import React, { FC, memo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { selectDazzlerLocale } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyFreeTextWidget'>;
};

export const FreeText: FC<Props> = ({ widget }) => {
  const selectedLocale = useSelector(selectDazzlerLocale);

  const localizedHtml =
    widget.shape?.html.find((html) => html.locale === selectedLocale)?.value ??
    '';

  const isInfoBox = widget.shape?.textVariant === 'INFO_BOX';

  return (
    <ContainedRow>
      <RichText
        className={classNames(isInfoBox && 'info-container')}
        styles={{ p: isInfoBox && 5 }}
        text={localizedHtml}
      />
    </ContainedRow>
  );
};

export default memo(FreeText);
