/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import RtsTicketCreditsRedemption from './RtsTicketCreditsRedemption';

import { TicketCredit, TicketTypeModel } from '../../../../@types/modelTypes';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import backend from '../../../../services/RestUtilities';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectLoyaltyRecognitionNumber,
  selectTicketTypes,
  selectToken,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import ActionButtonSpinner from '../../actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../../borderedcollapse/BorderedCollapse';
import ContainedRow from '../../layout/ContainedRow';

const RtsLoyaltyRecognition: React.FC = () => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();

  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const token = useSelector(selectToken);
  const [disableApplyButton, setDisableApplyButton] = useState(false);

  const loggedInCardNumber = useSelector(selectLoyaltyRecognitionNumber);
  const selectedTickets = useSelector(selectTicketTypes);
  const [loyaltyCardChecked, setLoyaltyCardChecked] = useState(
    !!loggedInCardNumber
  );
  const [loyaltyCardIsValid, setLoyaltyCardIsValid] = useState(
    !!loggedInCardNumber
  );
  const [loyaltyNumber, setLoyaltyNumber] = useState(loggedInCardNumber ?? '');
  const [showLoyaltyRecognitionForm, setShowLoyaltyRecognitionForm] =
    useState(false);

  const handleLoyaltyNumberCheck = async () => {
    setDisableApplyButton(true);

    const turnstileToken = await turnstile?.getToken();

    const data = {
      loyaltyNumber,
      dataToken: token,
    };
    const response = await backend.post(
      'api/Payment/CheckLoyaltyCard',
      data,
      turnstileToken
    );
    if (response.ok) {
      const hasTicketRedemptions =
        response.content?.ticketCreditsCount &&
        config.payment.enableTicketCreditRedemption;
      if (hasTicketRedemptions) {
        const actualTicketsSelected: TicketTypeModel[] | undefined =
          selectedTickets?.ticketTypeModels.filter((t) => t.quantity > 0);
        const ticketCredits: TicketCredit[] | undefined =
          actualTicketsSelected?.map((t: TicketTypeModel) => {
            return {
              max: t.quantity,
              used: 0,
              price: t.price,
            } as TicketCredit;
          });
        dispatch(actionCreators.setTicketCredits(ticketCredits));
        dispatch(
          actionCreators.setTicketCreditsAvailable(
            response.content.ticketCreditsCount
          )
        );
      } else {
        dispatch(actionCreators.setTicketCredits(undefined));
        dispatch(actionCreators.setTicketCreditsAvailable(0));
      }

      setLoyaltyCardChecked(true);
      setLoyaltyCardIsValid(response.content.isLoyaltyCardValid);
      dispatch(
        actionCreators.setLoyaltyRecognitionNumber(
          response.content.isLoyaltyCardValid ? loyaltyNumber : ''
        )
      );
    } else {
      dispatch(actionCreators.setTicketCredits(undefined));
      dispatch(actionCreators.setTicketCreditsAvailable(0));
      dispatch(actionCreators.setLoyaltyRecognitionNumber(''));
      setLoyaltyCardIsValid(false);
      dispatch(
        actionCreators.setError(content.error.loyaltyNumberErrorRichText)
      );
    }
    setDisableApplyButton(false);

    turnstile?.resetToken();
  };

  return (
    <div
      className='loyalty-recognition-wrapper'
      data-testid='loyalty-recognition'
    >
      <ContainedRow>
        <h2 sx={{ mt: 7, textAlign: 'center' }}>
          {content.payment.loyaltyRecognitionSubtitle}
        </h2>
      </ContainedRow>
      <ContainedRow styles={{ my: 5, textAlign: 'left' }}>
        <BorderedCollapse
          closeButtonText={content.payment.closeButtonText}
          heading={content.payment.loyaltyRecognitionHeading}
          setShow={setShowLoyaltyRecognitionForm}
          show={showLoyaltyRecognitionForm}
        >
          <>
            <Form>
              <Form.Label>{content.payment.loyaltyRecognitionLabel}</Form.Label>
              <Flex
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Box sx={{ pr: 4, flexGrow: 1 }}>
                  <Form.Control
                    id='loyaltyNumber'
                    name='loyaltyNumber'
                    type='number'
                    placeholder={content.payment.loyaltyRecognitionPlaceHolder}
                    value={loyaltyNumber}
                    onChange={(e) => setLoyaltyNumber(e.target.value)}
                  />
                </Box>
                <Box>
                  <ActionButton
                    onClick={() => {
                      if (loyaltyNumber) {
                        handleLoyaltyNumberCheck();
                      }
                    }}
                    variant='secondary'
                    mb={0}
                    mt={0}
                    disabled={disableApplyButton}
                  >
                    {disableApplyButton ? (
                      <ActionButtonSpinner />
                    ) : (
                      content.payment.loyaltyRecognitionButtonText
                    )}
                    {}
                  </ActionButton>
                </Box>
              </Flex>
            </Form>
            {loyaltyCardChecked && loyaltyCardIsValid && (
              <Box className='info-container' mt={5} p={5}>
                <p>{content.payment.loyaltyRecognitionSuccessText}</p>
              </Box>
            )}
            {loyaltyCardChecked && !loyaltyCardIsValid && (
              <Box className='warning-container' mt={5} p={5}>
                <p>{content.payment.loyaltyRecognitionErrorText}</p>
              </Box>
            )}

            <RtsTicketCreditsRedemption />
          </>
        </BorderedCollapse>
      </ContainedRow>
    </div>
  );
};

export default RtsLoyaltyRecognition;
