import { defineMessages } from 'react-intl';

export default defineMessages({
  guestMarketingSubtitle: {
    id: 'purchaser-details.guest-marketing-subtitle-text',
    defaultMessage: 'Marketing options',
  },
  guestMarketingRichText: {
    id: 'purchaser-details.guest-marketing-rich-text',
    defaultMessage:
      '<p>Tell us how we can contact you and what marketing materials you wish to receive.</p>',
  },
  guestMarketingNewsletterCheckboxText: {
    id: 'purchaser-details.guest-marketing-newsletter-checkbox-text',
    defaultMessage:
      'Check this box for special incentives and listings each week.',
  },
  guestMarketingThirdPartyCheckboxText: {
    id: 'purchaser-details.guest-marketing-third-party-checkbox-text',
    defaultMessage: 'Check this box for invitations to special events.',
  },
  guestNewsletterSignUpCheckboxText: {
    id: 'purchaser-details.guest-newsletter-sign-up-checkbox-text',
    defaultMessage: 'Sign up for our movie newsletter',
  },
  termsAndConditionsText: {
    id: 'purchaser-details.terms-and-conditions-text',
    defaultMessage: 'I have read and agree to the',
  },
  termsAndConditionsLinkText: {
    id: 'purchaser-details.terms-and-conditions-link-text',
    defaultMessage: 'Terms & Conditions',
  },
  termsAndConditionsRichText: {
    id: 'purchaser-details.terms-and-conditions-rich-text',
    defaultMessage: 'Here are some Terms and Conditions',
  },
  termsAndConditionsHeading: {
    id: 'purchaser-details.terms-and-conditions-heading-text',
    defaultMessage: 'Terms & Conditions',
  },
  continueToPaymentButtonText: {
    id: 'purchaser-details.continue-to-payment-button-text',
    defaultMessage: 'Continue to payment',
  },
  purchaserDetailsCollapsedSummaryHeadingText: {
    id: 'purchaser-details.collapsed-summary-heading-text',
    defaultMessage: 'The tickets will be sent to:',
  },
  purchaserDetailsCollapsedSummaryEditButtonText: {
    id: 'purchaser-details.collapsed-summary-edit-button-text',
    defaultMessage: 'Edit details',
  },
  purchaserDetailsFormSubtitle: {
    id: 'purchaser-details.form-subtitle-text',
    defaultMessage: 'Send my ticket(s) to...',
  },
  purchaserDetailsFormRichText: {
    id: 'purchaser-details.form-rich-text',
    defaultMessage: `<p>Please complete the recipients' details below. We'll use this information to send you your tickets.</p>`,
  },
  formErrorSubtitle: {
    id: 'purchaser-details.form-error-subtitle-text',
    defaultMessage: 'Oops. Something is wrong!',
  },
  formErrorMessage: {
    id: 'purchaser-details.form-error-message-text',
    defaultMessage: `Looks like you've missed something. Please check and complete any fields highlighted.`,
  },
});
