import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { JOURNEY_TYPES } from '../../../constants';
import { useMockedCypher } from '../../../hooks/useMockedCypher';
import { useStartConcessionsForCircuit } from '../../../hooks/useStartConcessionsForCircuit';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { selectStep } from '../../../store/Selectors';

const StartGiftCards: React.FC = () => {
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const step = useSelector(selectStep);
  const journeyType = JOURNEY_TYPES.GIFTCARDS_ONLY;
  const journeyTypeConfig = journeyTypeConfigs[journeyType];
  const pathname = `/${getRouteFromStep(journeyTypeConfig, step)}`;

  useMockedCypher();
  useStartConcessionsForCircuit(journeyType, setShouldRedirect);

  return shouldRedirect ? (
    <Navigate to={pathname} state={{ from: location }} />
  ) : null;
};

export default StartGiftCards;
