import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectLoyaltyRecognitionNumber,
} from '../../../../store/Selectors';
import ActionButton from '../../../common/actionbutton/ActionButton';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/da';
import 'moment/locale/pt';
import { resolveTicketingCMSStringOrDefault } from '../../helpers';
import messages from '../intl';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
}

const ZeroBankPayment: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();
  const { formatMessage } = useIntl();

  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const content = useSelector(selectContent);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const handleMakePayment = async () => {
    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          loyaltyCardNumber: loyaltyRecognitionNumber,
        },
        turnstile,
      })
    );
  };

  const isPaymentValid = isCustomerReadyForPayment;

  const handlePaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (isPaymentValid) {
      handleMakePayment();
    }
  };

  return (
    <div className='zero-payment' data-testid='zero-payment'>
      <ActionButton
        onClick={handlePaymentClick}
        showIcon
        showWarningMessage={isPageValidated && !isPaymentValid}
        mb={0}
        variant='primary'
      >
        {`${resolveTicketingCMSStringOrDefault(
          formatMessage(messages.submitText),
          content.payment.submitText
        )} ${priceToDisplay}`}
      </ActionButton>
    </div>
  );
};

export default ZeroBankPayment;
