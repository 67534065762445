/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import RichText from '../../common/richtext/RichText';
import SignUpSummaryTierItem from '../../common/signup/SignUpSummaryTierItem';

const SignupPayment: React.FC = () => {
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  useConfirmationPageRedirect();

  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  if (!content || !config || !bookingData) return null;

  return (
    <div className='loyalty payment' data-testid='loyalty-payment'>
      <div className='giftcards' sx={{ textAlign: 'center' }}>
        <ContainedRow>
          <h1>{content.loyalty.signupPaymentTitle}</h1>
        </ContainedRow>
        <ContainedRow>
          <h2 sx={{ mt: 5, textAlign: 'center' }}>
            {content.loyalty.signupPaymentHeading}
          </h2>
          <RichText
            text={content.loyalty.signupPaymentRichText}
            styles={{ textAlign: 'left' }}
          />
        </ContainedRow>
        <Box
          className='loyalty-rows-container'
          sx={{ maxWidth: '800px', marginX: 'auto', mt: 5 }}
        >
          <Box
            className='summary-row'
            sx={{ maxWidth: '800px', margin: '0 auto' }}
          >
            {concession ? (
              <SignUpSummaryTierItem
                {...concession}
                key={concession.id}
                defaultImage={
                  concession?.image?.replace('https://', '') ??
                  content.loyalty.defaultImage
                }
              />
            ) : null}
          </Box>
          <ContainedRow
            classNameWrapper='tiny'
            styles={{ pb: 5, textAlign: 'left' }}
          >
            <Grid className='detail' gap={2} columns={2}>
              <div>
                <div>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.loyalty.namePaymentLabel}
                  </span>{' '}
                  <span>{customer.name}</span>
                </div>
                <Box pt={1}>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.loyalty.emailPaymentLabel}
                  </span>{' '}
                  <span>{customer.email}</span>
                </Box>
                {customer.zipCodeIsValidated && (
                  <div sx={{ pt: 2 }}>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.loyalty.zipCodePaymentLabel}
                    </span>{' '}
                    <span>{customer.zipCode}</span>
                  </div>
                )}
              </div>
              <div>
                <div>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.loyalty.telephonePaymentLabel}
                  </span>{' '}
                  <span>{customer.telephone}</span>
                </div>

                <div sx={{ pt: 2 }}>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.loyalty.signupDateOfBirthLabel}
                  </span>{' '}
                  <span>
                    {customer.month} / {customer.day} / {customer.year}
                  </span>
                </div>
              </div>
            </Grid>

            <Box mt={4}>
              <Button
                onClick={() => navigate(-1)}
                variant='link'
                className='btn-edit'
                sx={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                {content.loyalty.paymentEditText}
              </Button>
            </Box>
          </ContainedRow>
          <div className='separator' />
        </Box>
      </div>

      <PaymentDesignWrapper
        giftcardEnabled={false}
        loyaltyPaymentEnabled={false}
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => {
          setValidateForms(true);
        }}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setGiftCardFormVisibility={undefined}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default SignupPayment;
