import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useBoostNavigate } from './useBoostNavigate';

import { JOURNEY_TYPES } from '../constants';
import {
  selectConfig,
  selectBookingData,
  selectToken,
  selectJourneyTypeConfig,
  selectSelectedTicketTypes,
  selectSelectedSeats,
} from '../store/Selectors';

export const useValidateJourney = () => {
  const boostNavigate = useBoostNavigate();
  const { externalCinemaId, externalSessionId } = useParams();
  const { pathname } = useLocation();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedTicketTypes = useSelector(selectSelectedTicketTypes);
  const selectedSeats = useSelector(selectSelectedSeats);

  let isValidRouteData =
    !!config &&
    !!token &&
    (!externalSessionId ||
      externalSessionId === bookingData.externalSessionId) &&
    (!externalCinemaId || externalCinemaId === bookingData.externalCinemaId);

  const isOnKioskStepInTicketingJourneyWithNoTicketsSelected =
    pathname.startsWith('/kiosk/') && !selectedTicketTypes.length;
  const isOnTicketsStepInSeatsFirstWithNoSeatsSelected =
    journeyTypeConfig.isSeatsFirst &&
    pathname.startsWith('/tickets/') &&
    !selectedSeats?.length;

  if (
    isValidRouteData &&
    journeyTypeConfig.isTicketingJourney &&
    (isOnKioskStepInTicketingJourneyWithNoTicketsSelected ||
      isOnTicketsStepInSeatsFirstWithNoSeatsSelected)
  ) {
    isValidRouteData = false;
  }

  useEffect(() => {
    if (
      isValidRouteData ||
      journeyTypeConfig.isConcessionsOnlyJourney ||
      journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS
    )
      return;

    if (bookingData) {
      boostNavigate.navigateToStartJourneyUsingGlobalState();
    } else {
      boostNavigate.navigateToStartJourneyUsingLocation();
    }
  }, [bookingData, boostNavigate, isValidRouteData, journeyTypeConfig]);
};
