/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import ApplePayButton from './ApplePayButton';
import SetupApplePayButton from './SetupApplePayButton';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import { splitLanguageCulture } from '../../../../../services/Helpers';
import backend from '../../../../../services/RestUtilities';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectLoyaltyRecognitionNumber,
} from '../../../../../store/Selectors';

const supportedByDevice = 'ApplePaySession' in window;

interface MerchantValidation {
  merchantId: string;
  storeName: string;
}

const ApplePayWorldPay = () => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const content = useSelector(selectContent);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const config = useSelector(selectConfig);
  const [canMakePayments, setCanMakePayments] = useState(false);
  const [merchantValidation, setMerchantValidation] = useState<
    MerchantValidation | undefined
  >(undefined);
  const [applePayPayment, setApplePayPayment] = useState<
    ApplePayJS.ApplePayPayment | undefined
  >(undefined);
  const [cannotSetupApplePay, setCannotSetupApplePay] = useState(false);

  const { language, country } = splitLanguageCulture(currencyConfig?.culture);

  // Get Merchant Validation
  useEffect(() => {
    const getMerchantIdentifier = async () => {
      const response = await backend.get(`api/ApplePay/getMerchantIdentifier/`);
      setMerchantValidation(response.content);
    };
    if (!merchantValidation) {
      getMerchantIdentifier();
    }
  }, [merchantValidation]);

  // Can Make Payments
  useEffect(() => {
    const canMakePaymentsWithActiveCard = async (id: string) => {
      if (!supportedByDevice) {
        return;
      }
      const result = await ApplePaySession.canMakePaymentsWithActiveCard(id);
      setCanMakePayments(result);
    };
    if (!merchantValidation) return;
    const canMakePayments =
      supportedByDevice && ApplePaySession.canMakePayments();
    if (canMakePayments) {
      setCanMakePayments(true);
    } else {
      canMakePaymentsWithActiveCard(merchantValidation.merchantId);
    }
    // then show ApplePay Button, else show SetupButton
  }, [merchantValidation]);

  const captureFunds = (
    payment: ApplePayJS.ApplePayPayment
  ): ApplePayJS.ApplePayPaymentAuthorizationResult => {
    setApplePayPayment(payment);
    return {
      status: ApplePaySession.STATUS_SUCCESS,
      errors: [],
    };
  };

  const createRequest = () => {
    const supportedNetworks: string[] = [];
    if (!config.payment.hideAmex) {
      supportedNetworks.push('amex');
    }
    if (!config.payment.hideDiscover) {
      supportedNetworks.push('discover');
    }
    if (!config.payment.hideMastercard) {
      supportedNetworks.push('masterCard');
    }
    if (!config.payment.hideVisa) {
      supportedNetworks.push('visa');
    }
    if (!config.payment.hideMaestro) {
      supportedNetworks.push('maestro');
    }

    const request: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: country,
      currencyCode: currencyConfig?.currency ?? 'USD',
      supportedNetworks: supportedNetworks,
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      total: { label: content.title, amount: String(bankCardAmount / 100) },
      requiredBillingContactFields: ['postalAddress'],
    };
    return request;
  };

  const getShippingUpdate = () => {
    const bankCardAmountString = String(bankCardAmount);
    const newTotal: ApplePayJS.ApplePayLineItem = {
      label: content.title,
      amount: bankCardAmountString,
    };
    const newLineItems: ApplePayJS.ApplePayLineItem[] = [
      { label: 'Subtotal', amount: bankCardAmountString, type: 'final' },
    ];
    const update: ApplePayJS.ApplePayShippingMethodUpdate = {
      newTotal,
      newLineItems,
    };
    return update;
  };

  const createSession = () => {
    if (!merchantValidation) return;
    const request = createRequest();
    const session: ApplePaySession = new ApplePaySession(14, request);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    session.oncancel = () => {};
    session.onvalidatemerchant = (
      event: ApplePayJS.ApplePayValidateMerchantEvent
    ) => {
      const data = {
        validationUrl: event.validationURL,
      };
      backend.post('api/ApplePay/ValidateMerchant', data).then((response) => {
        session.completeMerchantValidation(response.content);
      });
    };
    session.onshippingmethodselected = () => {
      const update = getShippingUpdate();
      session.completeShippingMethodSelection(update);
    };
    session.onpaymentauthorized = (
      event: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) => {
      const authorizationResult = captureFunds(event.payment);
      session.completePayment(authorizationResult);
    };
    session.begin();
  };

  const setupApplePay = async () => {
    if (!merchantValidation) return;
    const result = await ApplePaySession.openPaymentSetup(
      merchantValidation?.merchantId
    );
    if (result) {
      setCanMakePayments(true);
    } else {
      setCannotSetupApplePay(true);
    }
  };

  useEffect(() => {
    if (applePayPayment) {
      dispatch(
        actionCreators.submitMakePayment({
          makePaymentModelOverrideProps: {
            cardType: applePayPayment.token.paymentMethod.type,
            applePayPayment: applePayPayment,
            loyaltyCardNumber: loyaltyRecognitionNumber,
            paymentProvider: PaymentProvidersEnum.WORLDPAYAPPLEPAY,
          },
          turnstile,
        })
      );
    }
  }, [applePayPayment, dispatch, loyaltyRecognitionNumber, turnstile]);

  return (
    <>
      <Helmet>
        <script src='https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js' />
      </Helmet>
      {merchantValidation && supportedByDevice && (
        <div className='applepay-container' sx={{ minHeight: '50px', my: 4 }}>
          {canMakePayments && (
            <ApplePayButton createSession={createSession} language={language} />
          )}
          {!canMakePayments && !cannotSetupApplePay && (
            <SetupApplePayButton
              setupApplePay={setupApplePay}
              language={language}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ApplePayWorldPay;
