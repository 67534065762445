import React, { useState } from 'react';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import LoyaltyRedemptionBreakDown from './LoyaltyRedemptionBreakDown';

import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { displayPrice } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectCurrencyConfig,
  selectCustomer,
  selectIsCustomerReadyForPayment,
  selectMaxPayableByLoyaltyInCents,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import RichText from '../../richtext/RichText';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
}

const LoyaltyRedemption: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
}) => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();

  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const grandTotal = useSelector(selectMaxPayableByLoyaltyInCents);

  const [loyaltyRedemptionApplied, setLoyaltyRedemptionApplied] =
    useState(false);
  const currencyConfig = useSelector(selectCurrencyConfig);
  if (!content || !bookingData.isUserValidated) return null;

  const submitButtonText = `${content.payment.submitText} ${displayPrice(
    grandTotal,
    currencyConfig
  )}`;
  const isFormValid = isCustomerReadyForPayment;
  const haveEnoughBalance = bookingData.loyaltyCardBalance >= grandTotal;

  const handleApplyLoyaltyRedemption = () => {
    setLoyaltyRedemptionApplied(true);
    dispatch(actionCreators.setLoyaltyRedemptionRewards(grandTotal));
    setPaymentFormVisibility(false);
    !!setGiftCardFormVisibility && setGiftCardFormVisibility(false);
  };

  const handleLoyaltyPayment = async () => {
    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          payWithLoyaltyDollars: true,
          giftCards: [],
        },
        turnstile,
      })
    );
  };

  const handleLoyaltyPaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (customer.isValid) {
      handleLoyaltyPayment();
    }
  };

  return (
    <Box
      className='loyalty-redemption bordered'
      data-testid='loyalty-redemption'
      mt={7}
    >
      <h3 sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
        {content.payment.loyaltyRedemptionHeading.replaceAll(
          '##LoyaltyCreditApplied##',
          displayPrice(
            Math.min(bookingData.loyaltyCardBalance, grandTotal),
            currencyConfig
          )
        )}
      </h3>
      <RichText
        className={classnames(haveEnoughBalance || 'border-t border-b')}
        styles={{ mt: 4, py: haveEnoughBalance ? 0 : 4 }}
        text={content.payment.loyaltyRedemptionRichText}
      />

      {haveEnoughBalance && (
        <ActionButton
          onClick={handleApplyLoyaltyRedemption}
          disabled={loyaltyRedemptionApplied}
          data-testid={'loyalty-apply-btn'}
          variant='primary'
        >
          {content.payment.loyaltyRedemptionButtonText.replaceAll(
            '##LoyaltyCreditApplied##',
            displayPrice(
              Math.min(bookingData.loyaltyCardBalance, grandTotal),
              currencyConfig
            )
          )}
        </ActionButton>
      )}

      {haveEnoughBalance || (
        <Box className='info-container' sx={{ mt: 5, p: 5 }}>
          <p>{content.payment.loyaltyRedemptionNotEnoughBalanceText}</p>
        </Box>
      )}

      {loyaltyRedemptionApplied && (
        <React.Fragment>
          <LoyaltyRedemptionBreakDown
            grandTotal={grandTotal}
            setPaymentFormVisibility={setPaymentFormVisibility}
            setGiftCardFormVisibility={setGiftCardFormVisibility}
            setLoyaltyRedemptionApplied={setLoyaltyRedemptionApplied}
          />
          <ActionButton
            onClick={handleLoyaltyPaymentClick}
            showIcon
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            showWarningMessage={isPageValidated && !isFormValid}
            disabled={isPageValidated && !isFormValid}
            mb={0}
            variant='primary'
          >
            {submitButtonText}
          </ActionButton>
        </React.Fragment>
      )}
    </Box>
  );
};

export default LoyaltyRedemption;
