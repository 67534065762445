/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectGiftCardRecipient,
  selectIsCustomerReadyForPayment,
  selectSelectedGiftCards,
  selectShouldShowGuestMarketingOptions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import PaymentSummaryRowEGiftCard from '../../common/concessionsonlycommon/egiftcard/PaymentSummaryRowEGiftCard';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import GuestMarketing from '../../common/payment/others/GuestMarketing';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';

const GiftCardPayment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const shouldShowGuestMarketingOptions = useSelector(
    selectShouldShowGuestMarketingOptions
  );
  const showNewsLetterSignUp =
    config?.payment.showGuestNewsletterSignUpCheckbox &&
    !bookingData.isUserValidated;
  const canLaunchPayment = isCustomerReadyForPayment && !showNewsLetterSignUp;
  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);
  const [showPaymentForm, setShowPaymentForm] = useState(true);

  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const [creditCardType, setCreditCardType] = useState('');
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_PAYMENT_LANDING);

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setShowPaymentForm(nextShowPaymentForm);
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };

  const getGiftCardRows = () => {
    return selectedGiftCards.list.map((c: Concession, i: number) => (
      <PaymentSummaryRowEGiftCard
        selectedGiftCard={c}
        key={`giftcardrow_${i}`}
      />
    ));
  };

  if (!content || !config || !bookingData) return null;

  return (
    <div className='payment' data-testid='payment' sx={{ textAlign: 'center' }}>
      <div className='giftcards'>
        <Box sx={{ textAlign: 'center' }}>
          <h1>{content.giftCards.mainTitle}</h1>
        </Box>

        <ContainedRow>
          <h2 sx={{ mt: 5, textAlign: 'center' }}>
            {content.giftCards.paymentHeading}
          </h2>
          <RichText text={content.giftCards.paymentRichText} />
        </ContainedRow>

        <Box px={[4, 4, 0]}>
          <Box
            className='giftcard-rows-container summary-rows'
            mt={6}
            mx={9}
            px={3}
          >
            {getGiftCardRows()}
            <Box
              className='recipient-details summary tiny'
              pt={6}
              pb={7}
              sx={{ textAlign: 'left' }}
            >
              <ContainedRow>
                <div className='detail'>
                  <div>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.giftCards.paymentRecipientNameLabel}
                    </span>{' '}
                    <span>{giftCardRecipient.name}</span>
                  </div>
                  <div>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.giftCards.paymentRecipientEmailLabel}
                    </span>{' '}
                    <span>{giftCardRecipient.email}</span>
                  </div>

                  <div sx={{ mt: 2 }}>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.giftCards.paymentBillingNameLabel}
                    </span>{' '}
                    <span>{customer.name}</span>
                  </div>

                  <div>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.giftCards.paymentBillingEmailLabel}
                    </span>{' '}
                    <span>{customer.email}</span>
                  </div>
                  {giftCardRecipient.message && (
                    <div sx={{ mt: 2 }}>
                      <span sx={{ fontWeight: 'bold' }}>
                        {content.giftCards.paymentRecipientMessageLabel}
                      </span>{' '}
                      <span>{giftCardRecipient.message}</span>
                    </div>
                  )}
                </div>
                <Box mt={6}>
                  <Button
                    onClick={() => navigate(-1)}
                    variant='link'
                    className='btn-edit'
                    sx={{
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    {content.giftCards.paymentEditText}
                  </Button>
                </Box>
              </ContainedRow>
            </Box>
          </Box>
        </Box>
      </div>

      {shouldShowGuestMarketingOptions && <GuestMarketing />}

      <ContainedRow>
        <TermsAndConditions
          isPageValidated={isPageValidated}
          onEdit={resetHostedPayment}
        />
      </ContainedRow>

      {!isReadyForPayment && (
        <ContainedRow>
          <ActionButton
            onClick={handleValidatePage}
            showIcon
            showWarningMessage={isPageValidated && !isReadyForPayment}
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            mb={0}
            variant='primary'
          >
            {content.continueToPaymentText}
          </ActionButton>
        </ContainedRow>
      )}

      {isReadyForPayment && (
        <PaymentDesignWrapper
          giftcardEnabled={false}
          loyaltyPaymentEnabled={false}
          creditCardType={creditCardType}
          showPaymentForm={showPaymentForm}
          showGiftCardForm={false}
          isPageValidated={isPageValidated}
          handleValidatePage={handleValidatePage}
          setPaymentFormVisibility={setPaymentFormVisibility}
          setGiftCardFormVisibility={undefined}
          setCreditCardType={setCreditCardType}
        />
      )}
    </div>
  );
};

export default GiftCardPayment;
