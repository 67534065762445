import { call, put, select, takeLeading } from 'redux-saga/effects';

import { FoodAndBeverageJourneyDeliveryType } from '../../@types/enums';
import {
  AddTicketsRequestModel,
  SelectSeatsModel,
} from '../../@types/modelTypes';
import { JOURNEY_TYPES, PEACH_CODES } from '../../constants';
import backend from '../../services/RestUtilities';
import { actionCreators } from '../ActionCreators';
import {
  ADD_BASKET_TICKETS,
  ADD_SINGLE_TICKET,
  REMOVE_SINGLE_TICKET,
  VALIDATE_DYNAMIC_BASKET,
} from '../Actions';
import {
  selectConfig,
  selectContent,
  selectDonation,
  selectJourneyTypeConfig,
  selectTicketTypes,
  selectToken,
} from '../Selectors';

/* eslint-disable @typescript-eslint/no-explicit-any */

function* addSingleTicketHandler(action: any): any {
  yield put(actionCreators.setLoading(true));

  const config = yield select(selectConfig);
  const token = yield select(selectToken);

  const { ticketTypeModel } = action;

  const ticketTypeToAdd = {
    ...ticketTypeModel,
    quantity: 1,
  };
  const data = {
    ticketTypes: [ticketTypeToAdd],
    dataToken: token,
  };
  const response = yield call(backend.post, 'api/Tickets/PostTicket', data);
  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    yield put(actionCreators.setToken(response.content.dataToken));
    yield put(
      actionCreators.setBookingFeeAndTax(
        response.content.bookingFee,
        response.content.bookingFeeTax
      )
    );

    if (config.enableCountDown) {
      yield put(
        actionCreators.setCountDown(response.content.secondsToExpiration)
      );
    }
    yield put(actionCreators.setOrderExists());
  } else {
    const { error: errorContent } = yield select(selectContent);

    let errorMessage = '';
    if (
      [
        PEACH_CODES.seatingCapacityExceeded,
        PEACH_CODES.sessionSoldOut,
      ].includes(response.content.peachCode)
    ) {
      errorMessage = errorContent.sessionSoldOutRichText;
    } else {
      errorMessage =
        response.content.peachCode === PEACH_CODES.sessionNotBookable
          ? errorContent.sessionNotBookableRichText
          : errorContent.ticketsCouldNotBeAddedRichText;
    }

    yield put(
      actionCreators.setError(errorMessage, response.content.peachCode)
    );
  }
  yield put(actionCreators.setLoading(false));
}

function* removeSingleTicketHandler(action: any): any {
  yield put(actionCreators.setLoading(true));

  const config = yield select(selectConfig);
  const token = yield select(selectToken);

  const { ticketTypeModel } = action;

  const ticketTypeToAdd = {
    ...ticketTypeModel,
    quantity: 1,
  };
  const data = {
    ticketType: ticketTypeToAdd,
    dataToken: token,
  };
  const response = yield call(backend.post, 'api/Tickets/RemoveTicket', data);
  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    yield put(actionCreators.setToken(response.content.dataToken));
    yield put(
      actionCreators.setBookingFeeAndTax(
        response.content.bookingFee,
        response.content.bookingFeeTax
      )
    );

    if (config.enableCountDown) {
      yield put(
        actionCreators.setCountDown(response.content.secondsToExpiration)
      );
    }
  } else {
    const { error: errorContent } = yield select(selectContent);

    let errorMessage = '';
    if (
      [
        PEACH_CODES.seatingCapacityExceeded,
        PEACH_CODES.sessionSoldOut,
      ].includes(response.content.peachCode)
    ) {
      errorMessage = errorContent.sessionSoldOutRichText;
    } else {
      errorMessage =
        response.content.peachCode === PEACH_CODES.sessionNotBookable
          ? errorContent.sessionNotBookableRichText
          : errorContent.ticketsCouldNotBeAddedRichText;
    }

    yield put(
      actionCreators.setError(errorMessage, response.content.peachCode)
    );
  }
  yield put(actionCreators.setLoading(false));
}

function* addBasketTicketsHandler(action: any): any {
  yield put(actionCreators.setLoading(true));

  const content = yield select(selectContent);
  const token = yield select(selectToken);
  const donation = yield select(selectDonation);
  const ticketTypes = yield select(selectTicketTypes);

  const {
    setCeaTicketInsufficientFeedbackFunc,
    navigateToNextStepFunc,
    turnstile,
  } = action.payload;

  const turnstileToken = yield turnstile?.getToken();

  const data: AddTicketsRequestModel = {
    ticketTypes: ticketTypes.ticketTypeModels,
    dataToken: token,
    donationValue: donation,
  };
  const response = yield call(
    backend.post,
    'api/Tickets/PostTickets',
    data,
    turnstileToken
  );
  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    yield call(
      handleAddBasketTicketsSuccessResponse,
      response,
      navigateToNextStepFunc
    );
  } else if (response.content.peachCode === PEACH_CODES.ceaTicketInsufficient) {
    if (setCeaTicketInsufficientFeedbackFunc) {
      yield setCeaTicketInsufficientFeedbackFunc(
        content.tickets.ceaCardInsufficientTicketsText
      );
    }
  } else {
    let errorMessage;
    switch (response.content.peachCode) {
      case PEACH_CODES.seatingCapacityExceeded:
      case PEACH_CODES.sessionSoldOut:
        errorMessage = content.error.sessionSoldOutRichText;
        break;
      case PEACH_CODES.sessionNotBookable:
        errorMessage = content.error.sessionNotBookableRichText;
        break;
      case PEACH_CODES.unavailablePromotionTicket:
        errorMessage = content.error.promotionTicketsNotAvailableRichText;
        break;
      default:
        errorMessage = content.error.ticketsCouldNotBeAddedRichText;
        break;
    }

    yield put(
      actionCreators.setError(errorMessage, response.content.peachCode)
    );
  }

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
}

function* handleAddBasketTicketsSuccessResponse(
  response: any,
  navigateToNextStepFunc: () => void
): any {
  const journeyTypeConfig = yield select(selectJourneyTypeConfig);
  const config = yield select(selectConfig);

  yield put(actionCreators.setToken(response.content.dataToken));
  yield put(
    actionCreators.setBookingFeeAndTax(
      response.content.bookingFee,
      response.content.bookingFeeTax
    )
  );
  yield put(actionCreators.setOrderExists());

  if (
    [JOURNEY_TYPES.TICKET_FIRST, JOURNEY_TYPES.TICKET_FIRST_KIOSK].includes(
      journeyTypeConfig.type
    )
  ) {
    if (!response.content.seatsToSelectCount) {
      const nextJourneyType =
        config.currentCinema.foodAndBeverageTicketingJourney &&
        config.currentCinema.foodAndBeverageTicketingJourney !==
          FoodAndBeverageJourneyDeliveryType.Disabled
          ? JOURNEY_TYPES.TICKETS_KIOSK
          : JOURNEY_TYPES.TICKETS_ONLY;
      yield put(actionCreators.setJourneyType(nextJourneyType));
    } else {
      yield put(
        actionCreators.setSeatsModel(
          response.content.selectSeatsModel as SelectSeatsModel
        )
      );
    }
  }

  if (config.enableCountDown) {
    yield put(
      actionCreators.setCountDown(response.content.secondsToExpiration)
    );
  }

  yield put(
    actionCreators.setAppliedDealsWithDiscount(
      response.content.appliedDeals,
      response.content.totalDiscount
    )
  );

  yield navigateToNextStepFunc();
}

function* validateDynamicBasketHandler(action: any): any {
  yield put(actionCreators.setLoading(true));

  const token = yield select(selectToken);
  const content = yield select(selectContent);

  const {
    setCeaTicketInsufficientFeedbackFunc,
    navigateToNextStepFunc,
    turnstile,
  } = action.payload;

  const turnstileToken = yield turnstile?.getToken();

  const data = {
    dataToken: token,
  };
  const response = yield call(
    backend.post,
    'api/Tickets/ValidateDynamicBasket',
    data,
    turnstileToken
  );
  if (!response.ok) {
    yield put(
      actionCreators.setError(
        content.error.unexpectedErrorRichText,
        response.content.peachCode
      )
    );
  } else if (response.content.peachCode === PEACH_CODES.noError) {
    yield navigateToNextStepFunc();
  } else {
    yield setCeaTicketInsufficientFeedbackFunc(
      content.tickets.ceaCardInsufficientTicketsText
    );
  }

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
}

export function* addSingleTicketWatch() {
  yield takeLeading([ADD_SINGLE_TICKET], addSingleTicketHandler);
}

export function* removeSingleTicketWatch() {
  yield takeLeading([REMOVE_SINGLE_TICKET], removeSingleTicketHandler);
}

export function* addBasketTicketsWatch() {
  yield takeLeading([ADD_BASKET_TICKETS], addBasketTicketsHandler);
}

export function* validateDynamicBasketWatch() {
  yield takeLeading([VALIDATE_DYNAMIC_BASKET], validateDynamicBasketHandler);
}
