/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import TokenReplacer from '../../../../services/TokenReplacer';
import { selectContent } from '../../../../store/Selectors';
import ContainedRow from '../../../common/layout/ContainedRow';
import RichText from '../../../common/richtext/RichText';

const CardPaymentPromotionPaymentReminder: React.FC = () => {
  const content = useSelector(selectContent);

  return (
    <ContainedRow>
      <h2 sx={{ mt: 5, textAlign: 'center' }}>
        {content.payment.cardPaymentPromoTicketsPaymentPageReminderTitle}
      </h2>
      <RichText
        styles={{ mb: 2 }}
        text={TokenReplacer(
          content.payment.cardPaymentPromoTicketsPaymentPageReminderRichText
        )}
      />
    </ContainedRow>
  );
};

export default CardPaymentPromotionPaymentReminder;
