import React, { useRef, useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../../../@types/enums';
import { useGetPaymentProvider } from '../../../../hooks/useGetPaymentProvider';
import {
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectCountdownExpired,
  selectHostedPayments,
  selectMaxRetriesExceeded,
} from '../../../../store/Selectors';
import ActionButton from '../../../common/actionbutton/ActionButton';
import { resolveTicketingCMSStringOrDefault } from '../../helpers';
import messages from '../intl';

interface Props {
  paymentProvider: PaymentProvidersEnum;
}

const RtsVantivPayment: React.FC<Props> = ({ paymentProvider }) => {
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const content = useSelector(selectContent);
  const countdownExpired = useSelector(selectCountdownExpired);
  const maxRetriesExceeded = useSelector(selectMaxRetriesExceeded);
  const hostedPayments = useSelector(selectHostedPayments);
  const { formatMessage } = useIntl();

  const postBackForm = useRef<HTMLFormElement | null>(null);

  const hostedPayment = hostedPayments[paymentProvider];

  const launchHostedPayment = useCallback(() => {
    if (
      (paymentProvider === PaymentProvidersEnum.RTSVANTIV ||
        paymentProvider === PaymentProvidersEnum.VANTIVHP) &&
      postBackForm.current
    ) {
      postBackForm.current.submit();
    }
  }, [paymentProvider]);

  useGetPaymentProvider(paymentProvider);

  const handlePaymentClick = async () => {
    if (hostedPayment) {
      launchHostedPayment();
    }
  };

  return (
    <div className='hosted-payment' data-testid='vantiv-hosted-payment'>
      <ActionButton
        onClick={handlePaymentClick}
        disabled={maxRetriesExceeded || !hostedPayment}
        showIcon
        mb={0}
        mt={0}
        variant='primary'
      >
        {`${resolveTicketingCMSStringOrDefault(
          formatMessage(messages.submitText),
          content.payment.submitText
        )} ${priceToDisplay}`}
      </ActionButton>
      {!!hostedPayment && !countdownExpired && (
        <form
          action={hostedPayment.actionUrl}
          ref={postBackForm}
          method='post'
          data-testid={'vantiv-payment-form'}
        >
          <input
            type='hidden'
            name='paymentID'
            value={hostedPayment.transactionId}
          />
        </form>
      )}
    </div>
  );
};

export default RtsVantivPayment;
