import { call, put, select, takeLeading } from 'redux-saga/effects';

import backend from '../../services/RestUtilities';
import { actionCreators } from '../ActionCreators';
import { VALIDATE_CEA_CARD } from '../Actions';
import {
  selectContent,
  selectJourneyTypeConfig,
  selectToken,
} from '../Selectors';

function* validateCeaCardHandler(action) {
  const dataToken = yield select(selectToken);
  const journeyTypeConfig = yield select(selectJourneyTypeConfig);
  const { error: errorContent } = yield select(selectContent);

  const { ceaCardCode, handleValidateCeaCardResponse, turnstile } =
    action.payload;

  const turnstileToken = yield turnstile?.getToken();

  const loginRequestModel = {
    username: ceaCardCode,
    password: ceaCardCode,
    dataToken: dataToken,
    journeyType: journeyTypeConfig.type,
  };

  const response = yield call(
    backend.post,
    'api/Member/ValidateCeaCardCode',
    loginRequestModel,
    turnstileToken
  );

  if (!response.ok) {
    yield put(
      actionCreators.setError(
        errorContent.unexpectedErrorRichText,
        response.content.peachCode
      )
    );
  } else {
    yield handleValidateCeaCardResponse(response.content);
  }

  turnstile?.resetToken();
}

export function* validateCeaCardWatch() {
  yield takeLeading([VALIDATE_CEA_CARD], validateCeaCardHandler);
}
