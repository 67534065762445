/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import CustomerDetails from './CustomerDetails';

import { selectContent, selectCustomer } from '../../../store/Selectors';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

interface Props {
  isPageValidated: boolean;
  onEditCustomer?: () => void;
}

const CustomerDetailsCollapse: React.FC<Props> = ({
  isPageValidated,
  onEditCustomer,
}) => {
  const customer = useSelector(selectCustomer);
  const content = useSelector(selectContent);
  const [isOpen, setIsOpen] = useState(!customer.isValid);

  const onEditClick = () => {
    !!onEditCustomer && onEditCustomer();
    setIsOpen(true);
  };

  // used to collapse the box
  useEffect(() => {
    if (isPageValidated && customer.isValid) {
      setIsOpen(false);
    } else if (isPageValidated && !isOpen) {
      setIsOpen(true);
    }
  }, [customer.isValid, isOpen, isPageValidated]);

  return (
    <ContainedRow classNameWrapper='customer-wrapper' styles={{ mt: 4 }}>
      <Collapse in={!isOpen} key={'closed'}>
        <div>
          <div sx={{ mt: 4, textAlign: 'center' }}>
            <p className='tiny'>
              {content.payment.customerDetailsCollapsedSummaryHeadingText}
            </p>
            <p className='h2'>
              {customer.name}
              <span className='dot-separator'>·</span>
              {customer.email}
            </p>
          </div>
          <div
            className='collapse-btn-wrapper'
            sx={{ my: 5, textAlign: 'center' }}
          >
            <Button
              className='collapse-btn tiny'
              onClick={onEditClick}
              variant='link'
              sx={{
                backgroundColor: 'websiteBackground',
                borderColor: 'mostReadableOnWebsiteBackground',
                color: 'mostReadableOnWebsiteBackground',
                textAlign: 'center',
                '&:hover, &:focus, &.btn:first-child:active': {
                  backgroundColor: 'mostReadableOnWebsiteBackground',
                  borderColor: 'mostReadableOnWebsiteBackground',
                  color: 'leastReadableOnWebsiteBackground',
                },
              }}
            >
              {content.payment.customerDetailsCollapsedSummaryEditButtonText}
            </Button>
            <div
              className='collapse-btn-divider'
              sx={{
                borderTopColor: 'mostReadableOnWebsiteBackground',
                width: '100%',
              }}
            />
          </div>
        </div>
      </Collapse>
      <Collapse in={isOpen} key={'open'}>
        <div>
          <h2 sx={{ mt: 6, textAlign: 'center' }}>
            {content.payment.customerDetailsSubtitle}
          </h2>
          <RichText text={content.payment.customerDetailsRichText} />
          <CustomerDetails isPageValidated={isPageValidated} />
        </div>
      </Collapse>
    </ContainedRow>
  );
};

export default CustomerDetailsCollapse;
