import { defineMessages } from 'react-intl';

export default defineMessages({
  voucherEntryHeading: {
    id: 'voucher-entry.widget-heading',
    defaultMessage: 'Add voucher',
  },
  voucherEntryLabel: {
    id: 'voucher-entry.input-label',
    defaultMessage: 'Enter voucher code here',
  },
  voucherEntryPlaceholder: {
    id: 'voucher-entry.input-placeholder',
    defaultMessage: 'Enter voucher code here',
  },
  voucherEntryErrorMessage: {
    id: 'voucher-entry.error-message-text',
    defaultMessage: 'Error',
  },
  voucherEntryAlreadyAppliedMessage: {
    id: 'voucher-entry.already-applied-message-text',
    defaultMessage: 'Voucher already applied',
  },
});
