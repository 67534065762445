/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import PaymentIcons from './PaymentIcons';

import { PaymentConfig } from '../../../../@types/modelTypes';
import messages from '../intl';

interface Props {
  creditCardType: string;
  paymentDesignName: 'split' | 'tabbed';
  paymentConfig: PaymentConfig;
}

const PaymentIconsContainer: React.FC<Props> = ({
  creditCardType,
  paymentDesignName,
  paymentConfig,
}) => {
  const { formatMessage } = useIntl();
  const allPaymentIconsHidden =
    !paymentConfig.showApplePay &&
    !paymentConfig.showGooglePay &&
    paymentConfig.hideAmex &&
    paymentConfig.hideDiscover &&
    paymentConfig.hideMaestro &&
    paymentConfig.hideMastercard &&
    paymentConfig.hideVisa;

  const paymentIconsTextClassName =
    paymentDesignName == 'tabbed' ? 'tiny' : undefined;

  const showPaymentIconsText = paymentDesignName === 'split';

  return (
    <>
      {allPaymentIconsHidden || (
        <>
          {showPaymentIconsText && (
            <div
              className={classNames(
                'payment-logos-text tiny',
                paymentIconsTextClassName && paymentIconsTextClassName
              )}
              data-testid='payment-logos-text'
            >
              {formatMessage(messages.paymentLogosText)}
            </div>
          )}
          <div
            sx={{
              display: paymentDesignName == 'split' ? 'flex' : 'block',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            data-testid='payment-icons-container'
          >
            <PaymentIcons
              cardType={creditCardType}
              paymentConfig={paymentConfig}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentIconsContainer;
