import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';

import { selectDazzlerLocale } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyHeadingWidget'>;
};

interface TextAlign {
  [key: string]: 'left' | 'center' | 'right' | 'inherit';
}

export const Heading: FC<Props> = ({ widget }) => {
  const selectedLocale = useSelector(selectDazzlerLocale);
  const localizedContent =
    widget.shape?.content.find((content) => content.locale === selectedLocale)
      ?.value ?? '';
  const returnHeading = () => {
    switch (widget.shape?.level) {
      case 1:
        return <h1>{localizedContent}</h1>;
      case 2:
        return <h2>{localizedContent}</h2>;
      case 3:
        return <h3>{localizedContent}</h3>;
      default:
        return <h1>{localizedContent}</h1>;
    }
  };

  const textAlign: TextAlign = {
    LEFT: 'left',
    CENTER: 'center',
  };

  const selectedAlign = textAlign[widget.shape?.textAlign ?? 'CENTER'];

  return (
    <ContainedRow textAlign={selectedAlign}>{returnHeading()}</ContainedRow>
  );
};

export default memo(Heading);
