/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Collapse, Button } from 'react-bootstrap';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { PaymentProvidersEnum } from '../../../@types/enums';
import { ReactComponent as MinusSVG } from '../../../svgs/payment/minus.svg';
import { ReactComponent as AddSVG } from '../../../svgs/payment/plus.svg';

interface Props {
  setShow: () => void;
  show: boolean;
  headingContent?: React.ReactNode;
  paymentProvider: PaymentProvidersEnum;
  hasOnePaymentOption: boolean;
  isShownClassName?: string;
  styles?: ThemeUIStyleObject;
  removePaymentPadding?: boolean;
}

const CollapsiblePaymentProviderContainer: React.FC<
  PropsWithChildren<Props>
> = ({
  children,
  setShow,
  show,
  headingContent,
  paymentProvider,
  hasOnePaymentOption,
  isShownClassName,
  styles,
  removePaymentPadding,
}) => {
  const iconToDisplay = show ? (
    <MinusSVG
      className='icon-plus-minus'
      sx={{ display: 'flex', alignItems: 'center' }}
    />
  ) : (
    <AddSVG
      className='icon-plus-plus'
      sx={{ display: 'flex', alignItems: 'center' }}
    />
  );
  return (
    <div
      className={classnames(
        'bordered-collapse',
        hasOnePaymentOption ? 'hide-border' : show ? isShownClassName : '',
        PaymentProvidersEnum[paymentProvider]?.toLowerCase()
      )}
      sx={styles}
    >
      <Button
        className={classnames(
          'bordered-collapse-btn',
          hasOnePaymentOption && 'no-pointer'
        )}
        sx={{
          pt: 0,
          px: hasOnePaymentOption ? 0 : 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={() => !hasOnePaymentOption && setShow()}
        variant='link'
      >
        <>
          {!!headingContent && headingContent}
          {hasOnePaymentOption || iconToDisplay}
        </>
      </Button>
      <Collapse in={hasOnePaymentOption || show}>
        <Box
          className='bordered-collapse-open'
          sx={{ p: hasOnePaymentOption || removePaymentPadding ? 0 : 4 }}
        >
          {children}
        </Box>
      </Collapse>
    </div>
  );
};

export default CollapsiblePaymentProviderContainer;
